<template>
  <app-header v-if="hasSiteLayoutLoaded" class="padded" />
  <main class="padded">
    <router-view v-if="hasSiteLayoutLoaded"></router-view>
  </main>
  <app-footer v-if="hasSiteLayoutLoaded" />
</template>

<script>
import AppHeader from "./components/AppHeader.vue";
import AppFooter from "./components/AppFooter.vue";
import { provide } from "vue";
import useAxios from "./composables/useAxios";

export default {
  name: "App",
  components: { AppHeader, AppFooter },
  created() {
    document.addEventListener("mbSiteLayoutLoaded", () => {
      this.hasSiteLayoutLoaded = true;
    });
    const el = document.querySelector("mybring-site-layout");
    if (el && el.hasLoaded) this.hasSiteLayoutLoaded = true;
  },
  setup() {
    const fetcher = useAxios();
    provide("fetcher", fetcher);
  },
  data() {
    return {
      hasSiteLayoutLoaded: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.padded {
  padding-inline: 24px;
}
</style>
