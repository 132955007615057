import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./styles/main.scss";
import { makeServer } from "./server";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Toast, { PluginOptions, POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import {
  faXmark,
  faUserPlus,
  faCircleNotch,
  faTriangleExclamation,
  faPenToSquare,
  faMagnifyingGlass,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import JsonCSV from "vue-json-csv";

library.add(
  faXmark,
  faUserPlus,
  faCircleNotch,
  faTriangleExclamation,
  faPenToSquare,
  faMagnifyingGlass,
  faCopy,
  faFilter,
);

if (process.env.NODE_ENV === "development") {
  makeServer();
}

const toastOptions: PluginOptions = {
  maxToasts: 5,
  newestOnTop: true,
  position: POSITION.BOTTOM_LEFT,
  timeout: 5000,
  transition: "Vue-Toastification__fade",
};

createApp(App)
  .use(router)
  .component("font-awesome-icon", FontAwesomeIcon)
  .component("downloadCsv", JsonCSV)
  .use(Toast, toastOptions)
  .mount("#app");
