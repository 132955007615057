<template>
  <footer>Bring Shelfless - fulfilment for the Nordic market</footer>
</template>

<style lang="scss" scoped>
footer {
  margin-top: auto;
  min-height: 50px;
  background-color: #002f19;
  color: #c8dc8c;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
