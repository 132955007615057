import {
  createServer,
  Model,
  Serializer,
  belongsTo,
  hasMany,
  Factory,
} from "miragejs";
import humps from "humps";

const ApplicationSerializer = Serializer.extend({
  keyForCollection(modelName) {
    return this._container.inflector.pluralize(
      humps.decamelize(humps.camelize(modelName)),
    );
  },
  keyForModel(modelName) {
    return this._container.inflector.pluralize(modelName);
  },
  keyForAttribute(attr) {
    return humps.decamelize(attr);
  },
  keyForEmbeddedRelationship(modelName) {
    return humps.decamelize(modelName);
  },
});

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const mocked_json = {
  id: 11,
  title: "perfume Oil",
  description: "Mega Discount, Impression of A...",
  price: 13,
  discountPercentage: 8.4,
  rating: 4.26,
  stock: 65,
  brand: "Impression of Acqua Di Gio",
  category: "fragrances",
  thumbnail: "https://i.dummyjson.com/data/products/11/thumbnail.jpg",
  images: [
    "https://i.dummyjson.com/data/products/11/1.jpg",
    "https://i.dummyjson.com/data/products/11/2.jpg",
    "https://i.dummyjson.com/data/products/11/3.jpg",
    "https://i.dummyjson.com/data/products/11/thumbnail.jpg",
  ],
};

const mocked_jsonv2 = {
  glossary: {
    title: "example glossary",
    GlossDiv: {
      title: "S",
      GlossList: {
        GlossEntry: {
          ID: "SGML",
          SortAs: "SGML",
          GlossTerm: "Standard Generalized Markup Language",
          Acronym: "SGML",
          Abbrev: "ISO 8879:1986",
          GlossDef: {
            para: "A meta-markup language, used to create markup languages such as DocBook.",
            GlossSeeAlso: ["GML", "XML"],
          },
          GlossSee: "markup",
        },
      },
    },
  },
};

const returnMockedJSON = (request) => {
  const customer_number = request.queryParams.customer_number ?? "";
  const order_number = request.queryParams.order_number ?? "";

  if (customer_number.length === 0 || order_number.length === 0) return {};
  else return getRandomInt(0, 1) ? mocked_json : mocked_jsonv2;
};

export function makeServer({ environment = "development" } = {}) {
  const server = createServer({
    environment,

    models: {
      customer: Model.extend({ activity_logs: hasMany("activityLog") }),
      activityLog: Model.extend({ customer: belongsTo() }),
      kit: Model,
      warehouse: Model,
    },

    seeds(server) {
      const firstCustomer = server.create("customer", {
        customer_number: "100234762",
        name: "Business 1",
        email: "business1@example.com",
        warehouses: [
          {
            address: "test address 1234",
            name: "BERGER",
            warehouse_id: "780132",
          },
        ],
        ocs_configurations: [
          {
            config_type: "FEATURE",
            backorder_enabled: false,
          },
          {
            config_type: "VALIDATION",
            phone_validation: "ON",
            email_validation: "PASSTHROUGH",
            address_validation: "OFF",
          },
        ],
        kits: [
          {
            kit_sku: "sample kit SKU",
            type: "sample type",
            name: "Sample kit",
            items: [
              {
                sku: "sample item SKU",
                amount: 1,
              },
            ],
          },
        ],
        backorders: Array.from(Array(100).keys()).map(() => ({
          OrderNumber: "Sample order number",
          Registered: 0,
          Updated: 0,
        })),
        stock_adjustments: [
          {
            sku: "SKU123",
            warehouse: "BERGER",
            adjustment: -15,
            unit: "SKU123",
            balanceType: "PHYSICAL",
            reason: "broken item",
            sourceCreatedEpoch: 1607690460000000000,
            eventCreated: 1639473270765870600,
            batchNumber: "3",
            customerNumber: "100234762",
          },
        ],
      });
      const secondCustomer = server.create("customer", {
        customer_number: "200734587",
        name: "Business 2",
        email: "business2@example.com",
        warehouses: [],
        ocs_configurations: [
          {
            config_type: "FEATURE",
            backorder_enabled: false,
          },
          {
            config_type: "VALIDATION",
            phone_validation: "ON",
            email_validation: "PASSTHROUGH",
            address_validation: "OFF",
          },
        ],
        kits: [],
        backorders: [],
        stock_adjustments: [],
      });
      server.createList("customer", 50);
      server.create("warehouse", {
        address: "test address 1234",
        name: "BERGER",
        warehouse_id: "780132",
      });
      server.create("warehouse", {
        address: "test address 12345",
        name: "BERGER2",
        warehouse_id: "780133",
      });
      server.create("activityLog", {
        customer: firstCustomer,
        message: `Created customer ${firstCustomer.name} (${firstCustomer.customer_number})`,
        timestamp: Date.now(),
      });
      server.create("activityLog", {
        customer: secondCustomer,
        message: `Created customer ${secondCustomer.name} (${secondCustomer.customer_number})`,
        timestamp: Date.now(),
      });
    },

    factories: {
      customer: Factory.extend({
        customer_number(i) {
          return `${i}`;
        },
        name(i) {
          return `Generated Business ${i}`;
        },
        email(i) {
          return `generatedemail${i}@example.com`;
        },
        warehouses: [
          {
            address: "test address 1234",
            name: "BERGER",
            warehouse_id: "780132",
          },
        ],
        ocs_configurations: [
          {
            config_type: "FEATURE",
            backorder_enabled: false,
          },
          {
            config_type: "VALIDATION",
            phone_validation: "OFF",
            email_validation: "OFF",
            address_validation: "OFF",
          },
        ],
        kits: [],
        backorders: [],
        stock_adjustments: [],
      }),
    },

    serializers: {
      application: ApplicationSerializer,
      customer: ApplicationSerializer.extend({
        attrs: [
          "customer_number",
          "email",
          "name",
          "warehouses",
          "ocs_configurations",
          "activity_logs",
        ],
      }),
      warehouse: ApplicationSerializer.extend({
        attrs: ["address", "name", "warehouse_id"],
      }),
      activityLog: ApplicationSerializer.extend({
        attrs: ["id", "message", "timestamp"],
      }),
    },

    routes() {
      this.namespace = "/api/settings";

      this.get("/customers", function (schema, request) {
        const page = parseInt(request.queryParams.page) - 1;
        const limit = parseInt(request.queryParams.limit ?? "25");
        const sortBy = request.queryParams.sortBy;
        const sortType = request.queryParams.sortType;
        const search = request.queryParams.search;
        console.log(search);

        let searchKey;

        if (search) searchKey = new RegExp(search, "i");

        const all_customers = search
          ? schema.customers.where((customer) => {
              const hasMatch =
                `${customer.name}${customer.customer_number}${customer.email}`.search(
                  searchKey,
                );
              return hasMatch !== -1;
            })
          : schema.customers.all();

        let customers;

        if (sortBy) {
          customers = all_customers
            .sort((a, b) => {
              if (sortBy === "name")
                if (sortType === "asc") return b.name > a.name ? -1 : 1;
                else return b.name < a.name ? -1 : 1;
              else if (sortBy === "customer_number")
                if (sortType === "asc") {
                  return b.customer_number > a.customer_number ? -1 : 1;
                } else return b.customer_number < a.customer_number ? -1 : 1;
              else if (sortBy === "email")
                if (sortType === "asc") return b.email > a.email ? -1 : 1;
                else return b.email < a.email ? -1 : 1;
            })
            .slice(page * limit, page * limit + limit);
        } else {
          customers = all_customers.slice(page * limit, page * limit + limit);
        }

        const json = this.serialize(customers);

        json["customers"] = json["customers"].map((customer) => {
          const logs = customer["activity_logs"]
            .sort((a, b) => b.timestamp - a.timestamp)
            .slice(0, 10);

          const serialized_logs = this.serialize(logs);

          return {
            ...customer,
            activity_logs: serialized_logs["activity_logs"],
          };
        });

        json["customer_count"] = all_customers.length;

        return json;
      });

      this.get("/warehouses");

      this.post("/customers", (schema, request) => {
        const attrs = JSON.parse(request.requestBody);
        console.log(attrs);
        console.log(request.requestHeaders);
        // return new Response(
        //   400,
        //   {},
        //   {
        //     errors: ["invalid error 1", "invalid error 2"],
        //   }
        // );

        const {
          customer_number,
          name,
          email,
          warehouses,
          phone_validation,
          email_validation,
          address_validation,
          backorder_enabled,
        } = attrs;

        let warehouse = [];
        if (warehouses.includes("780132")) {
          warehouse = [
            ...warehouse,
            {
              address: "test address 1234",
              name: "BERGER",
              warehouse_id: "780132",
            },
          ];
        }
        if (warehouses.includes("780133")) {
          warehouse = [
            ...warehouse,
            {
              address: "test address 12345",
              name: "BERGER2",
              warehouse_id: "780133",
            },
          ];
        }

        const configs = [
          {
            config_type: "FEATURE",
            backorder_enabled,
          },
          {
            config_type: "VALIDATION",
            phone_validation,
            email_validation,
            address_validation,
          },
        ];

        const newUser = schema.customers.create({
          customer_number,
          name,
          email,
          warehouses: warehouse,
          ocs_configurations: configs,
        });

        newUser.createActivityLog({
          message: `Created customer ${newUser.name} (${newUser.customer_number})`,
          timestamp: Date.now(),
        });
      });

      this.patch("/customers/:id", (schema, request) => {
        const id = request.params.id;
        const attrs = JSON.parse(request.requestBody);
        console.log(attrs);
        console.log(request.requestHeaders);
        // return new Response(
        //   400,
        //   {},
        //   {
        //     errors: ["invalid error 1", "invalid error 2"],
        //   }
        // );

        const {
          name,
          email,
          warehouses,
          phone_validation,
          email_validation,
          address_validation,
          backorder_enabled,
        } = attrs;

        let warehouse = [];
        if (warehouses.includes("780132")) {
          warehouse = [
            ...warehouse,
            {
              address: "test address 1234",
              name: "BERGER",
              warehouse_id: "780132",
            },
          ];
        }
        if (warehouses.includes("780133")) {
          warehouse = [
            ...warehouse,
            {
              address: "test address 12345",
              name: "BERGER2",
              warehouse_id: "780133",
            },
          ];
        }

        const configs = [
          {
            config_type: "FEATURE",
            backorder_enabled,
          },
          {
            config_type: "VALIDATION",
            phone_validation,
            email_validation,
            address_validation,
          },
        ];

        const editedUser = schema.customers
          .findBy({ customer_number: id })
          .update({
            name,
            email,
            warehouses: warehouse,
            ocs_configurations: configs,
          });

        editedUser.createActivityLog({
          message: `Edited customer ${editedUser.name} (${editedUser.customer_number})`,
          timestamp: Date.now(),
        });
      });

      this.get("activity-logs", function (schema) {
        const activityLogs = schema.activityLogs
          .all()
          .sort((a, b) => b.timestamp - a.timestamp)
          .slice(0, 10);

        const json = this.serialize(activityLogs);

        return json;
      });

      this.get("article-data", (_, request) => {
        const customer_number = request.queryParams.customer_number ?? "";
        const sku = request.queryParams.sku ?? "";

        if (customer_number.length === 0 || sku.length === 0) return {};
        else return { article_number: sku, ...mocked_json };
      });

      this.get("articles", (_, request) => {
        const customer_number = request.queryParams.customer_number ?? "";

        if (customer_number.length === 0) return {};
        else
          return {
            articles: [...Array(10).keys()].map((_, key) => ({
              ...mocked_json,
              article_number: key,
            })),
          };
      });

      this.get("articles-in-stock", (_, request) => {
        // const customer_number = request.queryParams.customer_number ?? "";
        const available = request.queryParams.available === "true" ?? false;

        return [
          {
            sku: "sample aid",
            available_quantity: 0,
            physical_quantity: 0,
            reserved_quantity: 0,
            blocked_quantity: 0,
          },
        ].filter((article) => {
          if (available) return article.available_quantity > 0;
          return true;
        });
      });

      this.get("article-stock", (_, request) => {
        const customer_number = request.queryParams.customer_number ?? "";
        const sku = request.queryParams.sku ?? "";

        if (customer_number.length === 0 || sku.length === 0) return {};
        else return mocked_json;
      });

      this.get("sales-order", (_, request) => {
        return returnMockedJSON(request);
      });

      this.get("sales-order/status", (_, request) => {
        return returnMockedJSON(request);
      });

      this.get("purchase-order", (_, request) => {
        return returnMockedJSON(request);
      });

      this.get("purchase-order/status", (_, request) => {
        return returnMockedJSON(request);
      });

      this.get("return-order", (_, request) => {
        return returnMockedJSON(request);
      });

      this.get("return-order/status", (_, request) => {
        return returnMockedJSON(request);
      });

      this.get("work-order", (_, request) => {
        return returnMockedJSON(request);
      });

      this.get("work-order/status", (_, request) => {
        return returnMockedJSON(request);
      });

      this.get("shipping-mappings", (_, request) => {
        const customer_number = request.queryParams.customer_number ?? "";

        if (customer_number.length === 0) return [];
        else
          return {
            shipping_mappings: [...Array(50).keys()].map((index) => ({
              shipping_id: "sample-shipping-id-" + index,
              destination_country: "SE",
              source_system_channel: "channel-test",
              shipping_service: {
                carrier_code: "BPN" + index,
                service_code: "special-se-code-" + index,
                inco_terms: ["inco-1", "inco-2"],
                addon_codes: ["addon-1", "addon-2"],
              },
            })),
          };
      });

      this.get("sales-orders", (_, request) => {
        const customer_number = request.queryParams.customer_number ?? "";

        if (customer_number.length === 0) return [];
        else
          return {
            sales_orders: [...Array(5).keys()].map((index) => ({
              order_number: "sample-order-" + index,
              receiver: "sample-receiver-" + index,
              created_at: Date.now(),
              updated_at: Date.now(),
            })),
          };
      });

      this.get("kits", (schema, request) => {
        const customer_number = request.queryParams.customer_number ?? "";

        const user = schema.customers.findBy({
          customer_number: customer_number,
        });

        if (!user) return [];
        else
          return {
            kits: user.kits,
          };
      });

      this.get("backorders", (schema, request) => {
        const customer_number = request.queryParams.customer_number ?? "";

        const user = schema.customers.findBy({
          customer_number: customer_number,
        });

        if (!user) return [];
        else
          return {
            backorders: user.backorders,
          };
      });

      this.get("backorder", () => {
        // const customer_number = request.queryParams.customer_number ?? "";
        // const v2 = request.queryParams.v2 ?? "";
        return [
          {
            sku: "sample sku",
            ordered_quantity: 10,
            available_quantity: 0,
            physical_quantity: 0,
            reserved_quantity: 0,
            blocked_quantity: 0,
          },
        ];
      });

      this.get("stock-adjustments", (schema, request) => {
        const customer_number = request.queryParams.customer_number ?? "";
        const start_date = request.queryParams.start_date ?? "";
        const end_date = request.queryParams.end_date ?? "";

        console.log(start_date);
        console.log(end_date);

        const user = schema.customers.findBy({
          customer_number: customer_number,
        });

        if (!user) return [];
        else
          return {
            stock_adjustments: user.stock_adjustments,
          };
      });

      this.passthrough(
        "https://3z0h9ghhk6.execute-api.eu-north-1.amazonaws.com/dev/code",
      );
    },
  });

  return server;
}
