import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    redirect: "/customers",
  },
  {
    path: "/customers",
    name: "customers",
    component: () =>
      import(/* webpackChunkName: "customers" */ "../views/CustomerPage.vue"),
  },
  {
    path: "/sales-order",
    name: "sales-order",
    component: () =>
      import(
        /* webpackChunkName: "sales-order" */ "../views/SalesOrderPage.vue"
      ),
  },
  {
    path: "/articles",
    name: "articles",
    component: () =>
      import(/* webpackChunkName: "articles" */ "../views/ArticlePage.vue"),
  },
  {
    path: "/purchase-order",
    name: "purchase-order",
    component: () =>
      import(
        /* webpackChunkName: "purchase-order" */ "../views/PurchaseOrderPage.vue"
      ),
  },
  {
    path: "/return-order",
    name: "return-order",
    component: () =>
      import(
        /* webpackChunkName: "return-order" */ "../views/ReturnOrderPage.vue"
      ),
  },
  {
    path: "/work-order",
    name: "work-order",
    component: () =>
      import(/* webpackChunkName: "work-order" */ "../views/WorkOrderPage.vue"),
  },
  {
    path: "/shipping-mapping",
    name: "shipping-mapping",
    component: () =>
      import(
        /* webpackChunkName: "shipping-mapping" */ "../views/ShippingMappingPage.vue"
      ),
  },
  {
    path: "/kits",
    name: "kits",
    component: () =>
      import(/* webpackChunkName: "kits" */ "../views/KitsPage.vue"),
  },
  {
    path: "/backorders",
    name: "backorders",
    component: () =>
      import(/* webpackChunkName: "backorders" */ "../views/BackorderPage.vue"),
  },
  {
    path: "/stock-adjustments",
    name: "stock-adjustments",
    component: () =>
      import(
        /* webpackChunkName: "stock-adjustments" */ "../views/StockAdjustmentPage.vue"
      ),
  },
  {
    path: "/articles-in-stock",
    name: "articles-in-stock",
    component: () =>
      import(
        /* webpackChunkName: "articles-in-stock" */ "../views/StockPage.vue"
      ),
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
