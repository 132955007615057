import axios, {
  AxiosInstance,
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import waitForElement from "@/utils/waitForElement";

interface TopbarElement extends Element {
  getIdToken: () => Promise<string>;
  login: () => Promise<void>;
  logout: () => Promise<void>;
  isLoggedIn: () => Promise<boolean>;
}

export default function useAxios(): AxiosInstance {
  const fetcher = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    withCredentials: true,
  });

  const handleRequest = async (config: InternalAxiosRequestConfig) => {
    let token = "";
    const topbar = (await waitForElement("mybring-topbar")) as TopbarElement;

    token = await topbar.getIdToken();
    if (!token) await topbar.login();

    config.headers.setAuthorization(`Bearer ${token}`);

    return config;
  };

  const handleResponse = ({ data }: AxiosResponse) => data;

  const handleError = async (error: AxiosError) => {
    if (error.response?.status === 401) {
      const topbar = (await waitForElement("mybring-topbar")) as TopbarElement;
      const loggedIn = await topbar.isLoggedIn();

      if (loggedIn) await topbar.logout();
    }

    return Promise.reject(error);
  };

  fetcher.interceptors.request.use(handleRequest, handleError);
  fetcher.interceptors.response.use(handleResponse, handleError);

  return fetcher;
}
